import React from "react";
import DefaultLayout from "../../Layouts/DefaultLayout/defaultLayout";
import CookieConsent from "react-cookie-consent";
//scss
import "./home.scss";
//Components
import About from "../../Components/About/about";
import Folio from "../../Components/Folio/folio";
import Header from "../../Components/Header/header";

export default function Home() {
  return (
    <DefaultLayout>
      <div className="home_container">
        <Header />
        <About />
        <Folio />
        <CookieConsent
          location="bottom"
          buttonText="Ok"
          cookieName="myAwesomeCookieName2"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
        >
          This website uses cookies to enhance the user experience.{" "} By continuing to navigate this website you agree with the privacy policy.
        </CookieConsent>
      </div>
    </DefaultLayout>
  );
}
