import React, { useContext, useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
//SCSS
import "./blog.scss";
//Images
/* 
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css"; */
//Context
import { CtxContext } from "../../Context/ctx";
//Pagination

export default function Blog() {
  const { apiUrl } = useContext(CtxContext);
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    axios.get(`${apiUrl}/artigos`).then((response) => {
      setArticles(response.data);
      setLoading(false);
    });
  }, [apiUrl]);

  return (
    <Container fluid className="blog">
      <Row>
        <Col md={12}>
          <section className="blog-article-container">
            <ul>
              {loading ? 
                  <div className="loading">Loading...<div className="square"></div></div>
              : 
                articles.map((article) => (
                  <li key={article.id}>
                    <Link to={`/${article.slugArtigo}`}>
                      <div>
                        <h4>{article.titulo}</h4>
                        <small>{article.subtitulo}</small>
                      </div>
                      <hr />
                    </Link>
                  </li>
                ))
                }
            </ul>
          </section>
        </Col>
      </Row>
    </Container>
  );
}
