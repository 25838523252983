import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import {Link} from 'react-router-dom'
//CSS
import "./navtop.scss";
//Imgs
import FaceIcon from "../../Images/Facebook.png";
import LinkedInIcon from "../../Images/Linkedin.png";
import YoutubeIcon from "../../Images/YouTube.png";
import GitIcon from "../../Images/Github.png";
//Context
import { CtxContext } from "../../Context/ctx";

export default function NavTop() {
  const { lang, changeLang } = useContext(CtxContext);

  return (
    <div className="navtop-container">
      <div className="navtop-logo">
        <h5>
          {"{"}emanuel-dev{"}"}
        </h5>
      </div>
      <div className="navtop-info">
        <h5>{lang.navTop.message}</h5>
      </div>
      <div className="navtop-menu">
        <ul className="cube-menu">
          <li>
            <Link to="/">{lang.navTop.Menu.item1}</Link>
          </li>
          <li>
            <Link to="/blog">{lang.navTop.Menu.item2}</Link>
          </li>
          <li>
              <Button type="button" onClick={changeLang} size="sm" >
                {lang.lang === "en" ? "PT" : "EN" }
              </Button>
          </li>
        </ul>
        <ul className="cube-social-icons">
          <li>
            <a
              href="https://www.linkedin.com/in/emanuel-fa%C3%ADsca-19b100196/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="cube-social-img" src={LinkedInIcon} alt="icon" />
            </a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/emanuel.faisca"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="cube-social-img" src={FaceIcon} alt="icon" />
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/channel/UCEWC_3zEaze1Tjv-WS8Bz5g"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="cube-social-img" src={YoutubeIcon} alt="icon" />
            </a>
          </li>
          <li>
            <a
              href="https://github.com/EmanuelGF"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="cube-social-img" src={GitIcon} alt="icon" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
