export const settings = {
    dots: true,
    arrows: false,
    lazyLoad: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 2,
    draggable: true,
    autoplay: true,
    autoplaySpeed: 3000
  };