import React, {useState } from 'react'
//Languages
import en from "../Languages/en.json"
import pt from "../Languages/pt.json"

export const CtxContext = React.createContext()

export function CtxProvider(props) {
    const apiUrl = "https://api.emanuel-dev.com"
    const [language, setLanguage] = useState(en)

    function changeLang() {
        language.lang === "en" ? setLanguage(pt) : setLanguage(en);
    }

    return (
            <CtxContext.Provider value={{apiUrl: apiUrl, lang: language, changeLang: changeLang}}>
                {props.children}
            </CtxContext.Provider>
    )
}