import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//SCSS
import "./privacyPolicy.scss";


export default function PrivacyPolicy() {

  return (
    <Container fluid className="privacy-policy">
      <Row>
        <Col md={12}>
          <section className="privacy-policy-container">
            <h2>Privacy Policy (EN)</h2>
            <p>
                This website uses google analitics to provide data to it´s creator for the purpose of user experience enhancement. The creator of the website does not intend to collect data from the website visitors to give to third parties.
            </p>
            <p><i>By continuing to use and navigate this website you agree with the privacy policy.</i></p>
            <h2>Politica de privacidade (PT)</h2>
            <p>
                Este website usa o google analitics para a coleta de dados para que o criador possa melhorar a experiencia de navegação do mesmo. Não é a intenção do criador do website de coletar dados para disponibilizar para terceiros.
            </p>
            <p><i>Ao continuar a navegar neste website o utilizador concorda com a politica de privacidade.</i></p>

          </section>
        </Col>
      </Row>
    </Container>
  );
}
