import React, { useContext, useEffect, useState } from 'react'
import {
    Container,
    Row,
    Col
} from 'react-bootstrap'
//SCSS
import './folio.scss'
//IMAGES
/* import { LazyLoadImage } from "react-lazy-load-image-component";
import "../../../node_modules/react-lazy-load-image-component/src/effects/blur.css"; */
//Slider
import Slider from 'react-slick'
import {settings} from './sliderConf'
import axios from 'axios';
//Context
import {CtxContext} from '../../Context/ctx'
import { isEmpty } from 'lodash-es';

export default function Folio() {
    const {apiUrl, lang} = useContext(CtxContext)
    const [projects, setProjects] = useState([])

    useEffect(() => {
        axios.get(`${apiUrl}/trabalhos`)
        .then(response => {
            setProjects(response.data)
        })
    },[apiUrl])

    return (
        <Container fluid id="folio">
            <Row>
                <Col md={12} className="folio-title">
                    <h5>{lang.recentWork.title}</h5>
                </Col>
            </Row>
            <Row>
                <Col md={12} className="folio-slider-container">
                    <Slider {...settings} >
                        {!isEmpty(projects) && 
                            projects.map(project => (
                                <div key={project.id} className="folio-slider-item">
                                    <img src={apiUrl+project.imagem.url} alt=""/>
                                    <a href={project.link} target="_blank" rel="noopener noreferrer">
                                    {"<"}{project.titulo}{">"}
                                    </a>
                                </div>
                            ))    
                        }
                    </Slider>
                </Col>
            </Row>
        </Container>
    )
}