import React, { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
//SCSS
import "./footer.scss";
//Context
import {CtxContext} from "../../Context/ctx"

export default function Footer() {
  const {lang} = useContext(CtxContext)

  return (
    <Container fluid className="footer">
      <Row>
        <Col md={12} className="text-center">
          <div className="footer-year">
            © emanuel-dev {new Date().getFullYear()}
          </div>
          <div className="footer-quickmenu">
            <ul>
              <li>
                <a href="/privacy-policy">{lang.footer.linkText1}</a>
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
