import React, { useContext, useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
//import { useParams } from "react-router-dom";
//SCSS
import "./article.scss";
//Images
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
//Context
import { CtxContext } from "../../Context/ctx";
import { isEmpty } from "lodash-es";
//Markdown Renderers
import ReactMarkdown from "react-markdown";
import { renderers } from "../../Helpers/customMarkdown";

export default function ArticleTemplate(props) {
  const { apiUrl } = useContext(CtxContext);
  const { slug } = props.match.params;
  //const { slug } = useParams();
  const [article, setArticle] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios.get(`${apiUrl}/artigos?slugArtigo=${slug}`).then((response) => {
      setArticle(response.data[0]);
      setLoading(false);
    });
  }, [apiUrl, slug]);

  return (
    <Container fluid className="article">
      <Row>
        <Col md={12}>
          {loading ? (
            <div className="loading">
              Loading...<div className="square"></div>
            </div>
          ) : (
            !isEmpty(article) && (
              <section className="article-container">
                <h6>Autor: emanuel-dev</h6>
                <ins dateTime={article.created_at}>
                  <small>{article.created_at.slice(0, 10)}</small>
                </ins>

                <hr />

                <h2>{article.titulo}</h2>
                <h5>{article.subTitulo}</h5>

                <LazyLoadImage
                  src={`https://api.emanuel-dev.com${article.imagem.url}`}
                  width="70%"
                  alt=""
                  style={{ borderRadius: "10%" }}
                  effect="blur"
                />

                <br />
                <ReactMarkdown components={renderers(apiUrl)} children={article.conteudo}/>
                <hr />
                <a href="/blog">{"<"}go back{">"}</a>
              </section>
            )
          )}
        </Col>
      </Row>
    </Container>
  );
}
