import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
//Context
import { CtxProvider } from "./Context/ctx";
//Util
import { AppRoute } from "./Helpers/helpers.js";
//Layouts
import DefaultLayout from "./Layouts/DefaultLayout/defaultLayout";
//Pages
import NotFound from "./pages/NotFound/notFound";
import Blog from "./pages/Blog/blog";
import Home from "./pages/Home/home";
import PrivacyPolicy from "./pages/PrivacyPolicy/privacyPolicy";
//Templates
import ArticleTemplate from './Templates/Article/article'


export default function App() {
	return (
		<CtxProvider>
			<BrowserRouter>
				<Switch>
					<AppRoute exact path="/privacy-policy" layout={DefaultLayout} component={PrivacyPolicy} />
					<AppRoute exact path="/blog" layout={DefaultLayout} component={Blog} />
					<AppRoute exact path="/:slug" layout={DefaultLayout} component={ArticleTemplate} />
					
					<Route path="/" exact component={Home} />
					<Route path="*" exact component={NotFound} />
				</Switch>
			</BrowserRouter>
		</CtxProvider>
	);
}
