import React, { useContext } from 'react'
import {
    Container,
    Row,
    Col
} from 'react-bootstrap'
import './about.scss'
//Context
import {CtxContext} from "../../Context/ctx"

export default function About() {
    const {lang} = useContext(CtxContext)
    return (
        <Container fluid id="about">
            <Row>
                <Col md="12" >
                    <div className="about-title-animation">
                        <h1>{lang.about.title} </h1>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <div className="about-content">
                        <p className="prompt">{lang.about.aboutSection.fakePrompt1}</p>
                        <p>{lang.about.aboutSection.text1}</p>
                        <p>
                            - <u>{lang.about.aboutSection.expTitle}</u>: <br />
                            {lang.about.aboutSection.exp1} <br />
                            {lang.about.aboutSection.exp2} <br />
                            {lang.about.aboutSection.exp3}
                        </p>
                        <p>
                            - <u>{lang.about.aboutSection.eduTitle}</u>: <br />
                            {lang.about.aboutSection.edu1} <br /> 
                            {"{"}<a href="https://www.codecademy.com/profiles/EmanuelGF">{lang.about.aboutSection.edu2}</a>{"}"} <br /> 
                            {lang.about.aboutSection.edu3}
                        </p>

                        <p className="prompt">[emanuel-dev@localhost~]$</p>
                    </div>                
                </Col>
            </Row>
        </Container>
    )
}