/**React-Markdown renderers são responsaveis por transformar markdown costumizado. */

import React from "react";
//Imgs
/* import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css"; */

export const renderers = (apiUrl) => {
  return {
    /* image: ({ src, alt, ...props}) => {
      return (
          <LazyLoadImage
            src={apiUrl + src}
            alt={alt}
            effect="blur"
            style={{ width: "75%" }}
          />
      );
    }, */
    code: ({ className, ...props }) => {
      if (className === "language-ytvideo") {
        return (
            <iframe
              width="420"
              height="320"
              title="title"
              src={props.children[0]}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
        );
      } else {
        return null
      }
    },
  };
};
