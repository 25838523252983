import React, { useContext } from "react";
import {
	Container,
	Row,
	Col
} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import Particles from 'react-particles-js';
//CSS
import "./header.scss";
//IMAGES
/* import { LazyLoadImage } from "react-lazy-load-image-component";
import "../../../node_modules/react-lazy-load-image-component/src/effects/blur.css";
import EdevFoto from "../../Images/mebw.jpg"; */
import DanceGif from "../../Images/9cIT.gif"
//Context
import {CtxContext} from "../../Context/ctx"
//Particles conf.
import {config} from './particles'

export default function Header() {
	const {lang} = useContext(CtxContext)

	let currentClass = '';
    function changeSide(side) {
        const cube = document.querySelector('.cube')
        let showClass = 'show-' + side
        currentClass && cube.classList.remove(currentClass)
        cube.classList.add(showClass)
        currentClass = showClass;
    }
	
	return (
		<Container className="header p-0" fluid >
			<Row className="justify-content-center">	
				<Particles className="particles" params={config}/>		
				<Col md={12} className="header-menu">
					<div className="scene">
						<div className="cube">
							<div className="cube__face cube__face--front">
								<h3>{lang.header.home.title}</h3>
								<h5>{lang.header.home.intro}</h5>
								
								<p>{lang.header.home.subTitle}</p>
							</div>
							<div className="cube__face cube__face--top">
								<h3>{lang.header.folio.title}</h3>
								<h5>{lang.header.folio.text1}<a href="#folio">{lang.header.folio.linkText1}</a>{lang.header.folio.text2}</h5>
								<hr />
								<p>{lang.header.folio.text3}</p>
								<h5>{lang.header.folio.text4}</h5>
								<p>{lang.header.folio.text5}</p>
							</div>
							<div className="cube__face cube__face--right">
								<h3>{lang.header.blog.title}<Link to="/blog">{lang.header.blog.linkText4}</Link>!</h3>
								<p>{lang.header.blog.text1}</p>	
								<p>{lang.header.blog.text2}</p>
								<p>
									{lang.header.blog.text3} <br /> <br />
									<a href="https://www.linkedin.com/in/emanuel-fa%C3%ADsca-19b100196/" target="_blank" rel="noopener noreferrer">{lang.header.blog.linkText1}</a><br />
									
								</p>
								
							</div>
							<div className="cube__face cube__face--left">
								<h5>{lang.header.contacts.title}</h5>
								<p>{lang.header.contacts.text1}</p>
								<p>{lang.header.contacts.text2}</p>
								<h3>{lang.header.contacts.text3}</h3>
								<a href="mailto:emanuelfaisca@gmail.com" style={{textDecoration:"none", fontSize:"36px"}}>📩</a>
							</div>
							<div className="cube__face cube__face--back">
								<h3>{lang.header.credits.title}</h3>
								<p>{lang.header.credits.text1}</p>
								<p>{lang.header.credits.text2}</p>
								<h5>{lang.header.credits.title2}</h5>
								<p>{lang.header.credits.text4}</p>
								<p><a href="https://www.codecademy.com" target="_blank" rel="noopener noreferrer">{lang.header.credits.text5}</a></p>
								<p><a href="https://www.eddiehub.org/" target="_blank" rel="noopener noreferrer">{lang.header.credits.text6}</a></p>
							</div>
							<div className="cube__face cube__face--bottom">
								<h3>{lang.header.about.title}</h3>
								<img src={DanceGif} className="header-about-gif" alt=""/>
								<hr />
								<p>Scroll down and have a look!</p>
							</div>
						</div>
					</div>
				</Col>
				<Col md="12" className="text-center">
					<ul className="header-menu-buttons">
						<li><span onClick={() => changeSide("front")} href="/"> {lang.cubeMenu.linkText1} -</span></li>{" "}{" "} 
						<li><span onClick={() => changeSide("top")}href="/"> {lang.cubeMenu.linkText2} -</span></li> {" "}{" "}
						<li><span onClick={() => changeSide("right")}href="/"> {lang.cubeMenu.linkText3} -</span></li> {" "}{" "}
						<li><span onClick={() => changeSide("left")}href="/"> {lang.cubeMenu.linkText4} -</span></li> {" "}{" "}
						<li><span onClick={() => changeSide("bottom")}href="/"> {lang.cubeMenu.linkText5} -</span></li> {" "}{" "}
						<li><span onClick={() => changeSide("back")}href="/"> {lang.cubeMenu.linkText6} </span></li> 
					</ul>
				</Col>
			</Row>
		</Container>
	)
}
